(function ($) {
  Drupal.behaviors.editorialGridFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-editorial-grid-formatter-v1', context);

      // Loop through and init the carousel.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $template.each(function () {
        var $self = $(this);
        var $carousel = $self.find('.js-editorial-grid-formatter__carousel');
        var $arrowsDiv = $self.find('.editorial-grid-formatter__carousel-controls');
        var $dotsDiv = $self.find('.editorial-grid-formatter__carousel-dots');
        var $dataSlidePC = $carousel.attr('data-slides-pc');
        var $dataSlideMobile = $carousel.attr('data-slides-mob');
        var settings = {
          arrows: true,
          appendArrows: $arrowsDiv,
          prevArrow: '<div class="previous editorial-grid-formatter-carousel-arrow" role="button"><svg class="svgicon svgicon--arrow-16"><use xlink:href="#arrow-16"></use></svg></div>',
          nextArrow: '<div class="next editorial-grid-formatter-carousel-arrow" role="button"><svg class="svgicon svgicon--arrow-16"><use xlink:href="#arrow-16"></use></svg></div>',
          dots: true,
          appendDots: $dotsDiv,
          // prevent bug with dots not appearing active, disable infinite if scrolling more than one
          infinite: $self.data('slides-pc') && $self.data('slides-pc') > 1 ? false : true,
          slidesToShow: $dataSlidePC,
          slidesToScroll: 1,
          rtl: site.direction.isRTL,
          autoplaySpeed: 5000,
          adaptiveHeight: false,
          pauseOnFocus: true,
          responsive: [
            {
              breakpoint: 1024, // maps to $cr19-large-up
              settings: {
                adaptiveHeight: false,
                slidesToShow: $dataSlideMobile
              }
            }
          ]
        };

        // Init this carousel with our settings
        $carousel.slick(settings);
      });
    }
  };
})(jQuery);
